<template>
  <component :is="layout" />
</template>

<script>
import BodyLayout from './layouts/BodyLayout.vue';
import EmptyLayout from './layouts/EmptyLayout.vue';

export default {
  data() {
    return {
      layout: null,
    };
  },
  components: {
    BodyLayout, EmptyLayout
  },
  watch: {
    $route(to) {
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout
      } else {
        this.layout = "EmptyLayout"
      }
    },
  },
}
</script>
