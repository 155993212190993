import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        name: "loading",
        meta: {
            layout: "EmptyLayout",
        },
        component: () => import("../views/LoadingView.vue"),
    },
    {
        path: "/signup",
        name: "SignUp",
        meta: {
            layout: "BodyLayout",
        },
        component: () => import("../views/SignUpView.vue"),
    },
    {
        path: "/question",
        name: "Question",
        meta: {
            layout: "BodyLayout",
        },
        component: () => import("../views/QuestionView.vue"),
    },
    {
        path: "/thankyou",
        name: "ThankYou",
        meta: {
            layout: "BodyLayout",
        },
        component: () => import("../views/ThankYouView.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
