<template>
    <div class="layout">
        <div class="head">
            <h1>{{ this.game.game_name }}</h1>
            {{ this.game.game_description }}
        </div>
        <div class="body">
            <router-view />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            game: this.$cookies.get('_game')
        }
    }
}
</script>

<style>
body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    min-height: 100vh;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div {
    margin: 0;
    padding: 0;
}

#app {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.layout {
    border: 2px solid v-bind('game.game_bg_color');
    border-radius: 25px;
    overflow-x: hidden;
    height: 90vh;
    width: 100%;
    margin: 0 50px;
}

.head {
    padding: 50px;
    background-color: v-bind('game.game_bg_color');
    color: v-bind('game.game_text_color');
    text-align: center;
}

.head h1 {
    margin-bottom: 5px;
}

.body {
    padding: 25px 50px;
}
</style>